import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ScrollDown from "./scroll-down"
import { UserInterfaceContext } from "../context/user-interface"
import VohLockup from "../svg/voh-lockup"

const Welcome: React.FC = () => {
  const { firstname } = React.useContext(UserInterfaceContext)
  return (
    <>
      <section className="relative lg:min-h-[calc(100vh_-_105px)]">
        <StaticImage
          alt="Welcome to Open House"
          src="../images/01_Welcome_Option_1600x900_WhiteBottom.png"
          loading="eager"
          className="fluid !hidden lg:!block absolute lg:min-h-[calc(100vh_-_105px)]"
          width={2600}
        />
        <StaticImage
          alt=""
          width={1200}
          src="../images/1a_LandingPage_BKG_480x854_V01.jpg"
          loading="eager"
          className="lg:!hidden h-[400px]"
          imgClassName="lg:!hidden absolute h-[400px]"
        />
        <div className="z-50 absolute lg:bottom-[25px] xl:bottom-[30px] 2xl:bottom-[30px] w-full">
          <div className="container content">
            <ScrollDown />
          </div>
        </div>
        <div className="w-full absolute top-0">
          <div className="container content mt-12 lg:mt-24 flex flex-col lg:min-h-[calc(100vh-105px-6rem)]">
            <h3 className="text-2xl xl:text-4xl 2xl:text-5xl text-white leading-tight xl:leading-tight 2xl:leading-normal mb-4">
              Hi{firstname ? ` ${firstname}` : ``}!<br />
              Welcome to
            </h3>
            <h2 className="sr-only">Virtual Open House</h2>
            <VohLockup className="w-auto max-h-[275px] mr-auto lg:max-h-full lg:w-1/2 text-white" />
          </div>
        </div>
      </section>
    </>
  )
}

export default Welcome
