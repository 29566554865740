import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { UserInterfaceContext } from "../context/user-interface"

const DomesticApply: React.FC = () => (
  <section id="apply-today">
    <div className="content container my-16">
      <div className="lg:grid lg:grid-cols-4 lg:gap-12">
        <div className="lg:col-span-2 my-8 lg:my-0">
          <StaticImage
            src="../images/WOH24-APPLY-for-FREE-Domestic.png"
            alt="Apply for free"
            className="md:w-[134%] md:-ml-[34.5%] -ml-[10.5%]"
          />
          <h3 className="text-blue text-secondaryheading">NEED HELP?</h3>
          <p className="text-basic text-gray">
            Read our{` `}
            <a
              href="#faq"
              className="underline hover:text-green text-teal"
            >
              frequently asked questions (FAQs)
            </a>
            {` `}
            or join a drop-in help room for support with:
          </p>
          <ul className="flex flex-col my-2 list text-basic">
            <li>applying to Georgian,</li>
            <li>admissions questions, and</li>
            <li>international applications or admissions inquiries.</li>
          </ul>
          <a
            className="bg-button px-4 p-2 align-baseline text-button inline-block my-2 text-white"
            href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTRjMDQ1YmItMDhiNS00ZTNhLWJjMjktN2NlZTVkNzUxYzQ5%40thread.v2/0?context=%7b%22Tid%22%3a%22da9a94b6-4681-49bc-bd7c-bab9eac0ad3c%22%2c%22Oid%22%3a%22b59e1784-d229-4c4a-ae4c-d0d4685f6523%22%7d"
            target="_blank"
            rel="noreferrer"
          >
            Join the
            {` `}
            <strong className="font-semibold">domestic</strong>
            {` `}
            drop-in help room
          </a>
          <br />
          <a
            className="bg-button px-4 p-2 align-baseline text-button inline-block my-2 text-white"
            href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjI5NGQyNjMtZTE3My00NzZhLWJkNTktNTVjZGRlYTNlZjU5%40thread.v2/0?context=%7b%22Tid%22%3a%22da9a94b6-4681-49bc-bd7c-bab9eac0ad3c%22%2c%22Oid%22%3a%22ea57f214-4274-4eb3-9184-0fd090437e3d%22%7d"
            target="_blank"
            rel="noreferrer"
          >
            Join the
            {` `}
            <strong className="font-semibold">international</strong>
            {` `}
            drop-in help room
          </a>
        </div>

        <div className="lg:col-span-1 my-8 lg:my-0 flex flex-col gap-4 md:pt-24">
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              1
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Start your OCAS application
              </h3>
              <ul className="list">
                <li className="text-basic">
                  Watch this{` `}
                  <a
                    className="underline hover:text-green text-teal"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://youtu.be/iG5oDFgkkE0"
                  >
                    video tutorial
                  </a>
                  {` `}to learn how to apply on{` `}
                  <a
                    className="underline hover:text-green text-teal"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://ontariocolleges.ca/"
                  >
                    OntarioColleges.ca
                  </a>
                  .
                </li>
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              2
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Select at least one Georgian program
              </h3>
              <ul className="list">
                <li className="text-basic">
                  Choose at least one{` `}
                  <a
                    className="underline hover:text-green text-teal"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://www.georgiancollege.ca/academics/programs/"
                  >
                    Georgian program
                  </a>
                  {` `}and make sure you meet admission requirements.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              3
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Exit your application at the payment page
              </h3>
              <ul className="list">
                <li className="text-basic">
                  Once you get to the payment step of the application, you can
                  exit your application. All of your details will be saved. 
                </li>

                <li className="text-basic">Do NOT complete payment.</li>

                <li className="text-basic">
                  If you started your application previously, make sure to log
                  in on Jan. 27 and complete your application up to the payment
                  page.
                </li>

                <li className="text-basic">You do not need a voucher code.</li>

                <li className="text-basic">
                  If you have already completed the payment, unfortunately we
                  cannot reimburse the application fee.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 my-8 lg:my-0 flex flex-col gap-4 md:pt-24">
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              4
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Wait for an email from OCAS
              </h3>
              <ul className="list">
                <li className="text-basic">
                  OCAS will send you an email by Jan. 31 confirming that the
                  application fee has been waived.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              5
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Optional: Request and pay for transcripts
              </h3>
              <ul className="list">
                <li className="text-basic">
                  If you have requested transcripts, OCAS will remove the
                  request.
                </li>
                <li className="text-basic">
                  Once you receive the email from OCAS, return to your
                  application to order and pay for your transcripts.
                </li>
                <li className="text-basic">
                  Transcripts must be received prior to Feb. 1 to be considered
                  for equal consideration.{` `}
                </li>
              </ul>
              <a className="cursor-pointer" href="#faq">
                <StaticImage
                  src="../images/LearnMORE_Circle_Icon_Graident-01.png"
                  alt="Learn more about how to apply to Lakehead Georgian"
                  className="mt-8"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
const InternationalApply: React.FC = () => (
  <section id="apply-today">
    <div className="content container my-16">
      <div className="lg:grid lg:grid-cols-4 lg:gap-12">
        <div className="lg:col-span-2 my-8 lg:my-0">
          <StaticImage
            src="../images/WOH24-APPLY-for-FREE-International.png"
            alt="Step 3: Apply for free today!"
            className="md:w-[134%] md:-ml-[34.5%] -ml-[10.5%]"
          />
          <h3 className="text-blue text-secondaryheading">NEED HELP?</h3>
          <p className="text-basic text-gray">
            Read our{` `}
            <a
              href="#faq"
              className="font-semibold underline hover:text-green text-teal"
            >
              frequently asked questions (FAQs)
            </a>
            {` `}
            or join our{` `}
            <a
              className="underline hover:text-green text-teal"
              href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjI5NGQyNjMtZTE3My00NzZhLWJkNTktNTVjZGRlYTNlZjU5%40thread.v2/0?context=%7b%22Tid%22%3a%22da9a94b6-4681-49bc-bd7c-bab9eac0ad3c%22%2c%22Oid%22%3a%22ea57f214-4274-4eb3-9184-0fd090437e3d%22%7d"
              target="_blank"
              rel="noreferrer"
              className="font-semibold underline hover:text-green text-teal"
            >
              international drop-in help room
            </a>
            {` `}for questions about:
          </p>
          <ul className="flex flex-col my-2 list text-basic">
            <li>studying at Georgian as an international student,</li>
            <li>international application process,</li>
            <li>admissions, and</li>
            <li>program eligibility criteria.</li>
          </ul>
          <a
            className="font-semibold bg-button px-4 p-2 align-baseline text-button inline-block my-2 text-white"
            href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjI5NGQyNjMtZTE3My00NzZhLWJkNTktNTVjZGRlYTNlZjU5%40thread.v2/0?context=%7b%22Tid%22%3a%22da9a94b6-4681-49bc-bd7c-bab9eac0ad3c%22%2c%22Oid%22%3a%22ea57f214-4274-4eb3-9184-0fd090437e3d%22%7d"
            target="_blank"
            rel="noreferrer"
          >
            Join the
            {` `}
            <strong className="font-semibold">international</strong>
            {` `}
            drop-in help room
          </a>
        </div>

        <div className="lg:col-span-1 my-8 lg:my-0 flex flex-col gap-4 md:pt-24">
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              1
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Start your IAS application
              </h3>
              <ul className="list">
                <li className="text-basic">
                  Begin your application by visiting the{` `}
                  <a
                    className="underline hover:text-green text-teal"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://international.ocas.ca/applicant?referring_college=GEOR"
                    className="font-semibold underline hover:text-green text-teal"
                  >
                    IAS Portal
                  </a>
                  .
                </li>
                <li className="text-basic">
                  Learn more about{` `}
                  <a
                    className="underline hover:text-green text-teal"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://ontariocolleges.ca/en/apply/international-applicants"
                    className="font-semibold underline hover:text-green text-teal"
                  >
                    applying to college as an international student
                  </a>
                  .
                </li>
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              2
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Select at least one Georgian program
              </h3>
              <ul className="list">
                <li className="text-basic">
                  Choose at least one Georgian program and make sure you meet
                  {` `}
                  <a
                    className="underline hover:text-green text-teal"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://www.georgiancollege.ca/international/admissions/"
                    className="font-semibold underline hover:text-green text-teal"
                  >
                    admission requirements
                  </a>
                  {` `}
                  (varies based on country, language and program).
                </li>
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              3
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Exit your application at the payment page and 
                fill out the form below to receive a waiver code. 
               </h3>
              <ul className="list">
                <li className="text-basic">
                  On the day of Virtual Open House (Jan. 27), request a free informational application fee waiver code by filling out the {` `}
                  <a
                    href="https://forms.office.com/Pages/ResponsePage.aspx?id=tpSa2oFGvEm9fLq56sCtPBTyV-p0QrNOkYQP0JBDfj1UQzUyT0JRWjJUUE02QkdXVjg4N1c1QjBHQy4u"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                    className="font-semibold underline hover:text-green text-teal"
                  >
                    request an application waiver code form
                  </a>
                  {` `}
                  .
                </li>
                <li className="text-basic">Do NOT complete payment.</li>
                <li className="text-basic">
                  Wait until you receive the waiver code from Georgian (canada@georgiancollege.ca) to submit
                  your application.
                </li>
                <li className="text-basic">
                  If you have already completed the payment, unfortunately we
                  cannot reimburse the application fee.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 my-8 lg:my-0 flex flex-col gap-4 md:pt-24">
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              4
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Wait for an email from Georgian
              </h3>
              <ul className="list">
                <li className="text-basic">
                  Georgian (
                  <a
                    href="mailto:canada@georgiancollege.ca"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                    className="font-semibold underline hover:text-green text-teal"
                  >
                    canada@georgiancollege.ca
                  </a>
                  ) will send you an email with a waiver code that you can use
                  to submit your IAS application.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex-shrink-0 text-center block text-2xl font-bold text-white from-blue-vivid via-teal-vivid to-green-vivid bg-gradient-to-tr animate-gradient-xy w-8 h-8 rounded-full">
              5
            </div>
            <div>
              <h3 className="mb-3 text-blue text-secondaryheading">
                Return to apply the waiver code and submit your application
              </h3>
              <ul className="list">
                <li className="text-basic">Return to your IAS application.</li>
                <li className="text-basic">
                  In the <i>Coupon Code</i> field, enter the waiver code Georgian has
                  provided you.
                </li>
                <li className="text-basic">
                  After the waiver code is validated, click <i>Pay Now</i> to submit
                  your application.{` `}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const Apply = (): React.ReactElement => {
  const { studenttype } = React.useContext(UserInterfaceContext)
  return studenttype === `International` ? (
    <InternationalApply />
  ) : (
    <DomesticApply />
  )
}

export default Apply
