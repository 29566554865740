import React from "react"
import classNames from "../utils/classnames"
import { UserInterfaceContext } from "../context/user-interface"
import { StaticImage } from "gatsby-plugin-image"
import Modal from "./modal"

const MakeTheMost: React.FC = () => {
  const { studenttype, applicanttype } = React.useContext(UserInterfaceContext)
  return (
    <section id="make-the-most">
      <div className={classNames(`relative isolate overflow-hidden`)}>
        <StaticImage
          src="../images/GradientBanner.png"
          alt=""
          className={classNames(`!absolute top-0 left-0 h-full w-full z-[-1]`)}
        />
        <h3 className="text-white text-3xl lg:text-2xl container content py-8">
          <span className="block text-subheading">
            How to make the most of your
          </span>
          <span className="block text-primaryheading font-bold uppercase">
            Virtual Open House!
          </span>
        </h3>
      </div>

      <div className="content container my-16">
        <div className="lg:grid lg:grid-cols-4 lg:gap-12">
          <div className="lg:col-span-1 my-8 lg:my-0">
            <StaticImage
              src="../images/Step1_GetReady_Jan22.png"
              alt="Step 1: Get ready"
              className="mb-[60px]"
            />
            <ul className="flex flex-col gap-2">
              <li className="text-basic list">
                <span>
                  For the best experience,{` `}
                  <a
                    href="https://www.microsoft.com/en-ca/microsoft-365/microsoft-teams/download-app"
                    target="_blank"
                    rel="noreferrer"
                    className="underline hover:text-green text-teal"
                  >
                    download the Microsoft Teams app
                  </a>
                  {` `}
                  on your computer, tablet or mobile device (Android or Apple)
                  {` `}
                  <strong className="font-semibold">before the event</strong>.
                </span>
              </li>
            </ul>
            <div className="hidden">
              <Modal
                opener={
                  <StaticImage
                    src="../images/Watch_Our_Video_Kailey-crop.png"
                    alt="Kailey Hawkins, Senior Student Recruitment Specialist at Georgian College"
                    className="mb-4"
                  />
                }
              >
                <h1 className="text-secondaryheading text-blue mb-4">
                  Watch our welcome video
                </h1>
                <iframe
                  className="w-full aspect-video"
                  src="https://www.youtube.com/embed/ZK1Eo5LC81Y"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Modal>

              <p className="text-basic">
                A message from Kailey Hawkins |{` `}
                <span className="italic">
                  Senior Student Recruitment Specialist at Georgian College
                </span>
              </p>
            </div>
          </div>
          <div className="lg:col-span-1 my-8 lg:my-0">
            <StaticImage
              src="../images/Step2_PlanYourDay_Jan22.png"
              alt="Step 2: Plan your day"
              className="mb-4"
            />
            <ul>
              {studenttype === `International` && (
                <li className="text-basic list">
                  <span>
                    <strong className="font-semibold">
                      Join the international drop-in help room
                    </strong>
                    {` `}
                    to learn more about studying at Georgian as an international
                    student and get answers to your questions about applications
                    and admissions.
                  </span>
                </li>
              )}
              <li className="text-basic list">
                <span>
                  <strong className="font-semibold">
                    Learn about your program{`(s)`}
                  </strong>
                  {` `}
                  of choice through our academic drop-in rooms.
                </span>
              </li>
              <li className="text-basic list">
                <span>
                  <strong className="font-semibold">
                    Learn about student services
                  </strong>
                  {` `}
                  at our live, interactive sessions.
                </span>
              </li>
              <li className="text-basic list">
                <span>
                  <a
                    href="#services-agenda"
                    className="underline hover:text-green text-teal"
                  >
                    <strong className="font-semibold">View our agenda</strong>
                  </a>
                  {` `}
                  to find sessions that you might be interested in attending.
                </span>
              </li>
              <li className="text-basic list">
                <span>
                  <strong className="font-semibold">
                    Links to join the sessions
                  </strong>
                  {` `}
                  will appear on the day of the event (Jan. 27) beginning at 9
                  a.m.
                </span>
              </li>
            </ul>
          </div>
          <div className="lg:col-span-1 my-8 lg:my-0">
            <StaticImage
              src="../images/roadmap/Step-3--Apply-For-Free_Ashley.png"
              alt="Step 3: Apply for free January 21st"
              className={classNames(
                studenttype?.toLowerCase() === `international`
                  ? `!inline-block`
                  : `!hidden`
              )}
            />

            <StaticImage
              src="../images/roadmap/Step-3--Apply-For-Free_Kailey.png"
              alt="Step 3: Apply for free January 21st"
              className={classNames(
                studenttype?.toLowerCase() !== `international` &&
                  !(
                    applicanttype?.toLowerCase() === `applied` ||
                    applicanttype?.toLowerCase() === `offered` ||
                    applicanttype?.toLowerCase() === `accepted`
                  )
                  ? `!inline-block`
                  : `!hidden`
              )}
            />

            <StaticImage
              src="../images/roadmap/Step-3--Apply-For-Free_Kailey-Track-Your-App.png"
              alt="Step 3: Apply for free January 21st"
              className={classNames(
                studenttype?.toLowerCase() === `domestic` &&
                  (applicanttype?.toLowerCase() === `applied` ||
                    applicanttype?.toLowerCase() === `offered` ||
                    applicanttype?.toLowerCase() === `accepted`)
                  ? `!inline-block`
                  : `!hidden`
              )}
            />

            <ul>
              {studenttype?.toLowerCase() === `domestic` &&
                (applicanttype?.toLowerCase() === `applied` ||
                  applicanttype?.toLowerCase() === `offered` ||
                  applicanttype?.toLowerCase() === `accepted`) ? (
                <>
                  <li className="text-basic list">
                    Receive up-to-date information on the status of your
                    application by visiting the{` `}
                    <a
                      href="https://www.georgiancollege.ca/applicant"
                      className="underline hover:text-green text-teal"
                    >
                      <strong className="font-semibold">
                        MyGCLife applicant portal
                      </strong>
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="text-basic list">
                    <strong className="font-semibold">
                      Find out how you can apply for FREE&nbsp;
                    </strong>
                    at Virtual Open House (Jan. 27).
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="lg:col-span-1 my-8 lg:my-0">
            <StaticImage
              src="../images/4_Explore_MORE_AODA-01.png"
              alt="Step 4: Explore more"
              className="mb-8"
            />
            <ul>
              <li className="text-basic list">
                <span>
                  <strong className="font-semibold">
                    Have a question during the day?
                  </strong>
                  {` `}
                  Use our <strong className="font-semibold">LIVE CHAT</strong>
                  {` `}
                  to connect with us in real time for help navigating the event.
                  Click the live chat icon in the bottom right corner to get
                  started.
                </span>
              </li>
              <li className="text-basic list">
                <span>
                  Check out{` `}
                  <strong className="font-semibold">
                    virtual campus and residence tours
                  </strong>
                  , and explore our{` `}
                  <strong className="font-semibold">campus communities</strong>.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MakeTheMost
