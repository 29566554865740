import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Welcome from "../components/welcome"
import ConnectMore from "../components/connect-more"
import DiscoverMore from "../components/discover-more"
import FlexMore from "../components/flex-more"
import CareerMatchBanner from "../components/career-match-banner"
import AffordMore from "../components/afford-more"
import ThriveMore from "../components/thrive-more"
import SucceedMore from "../components/succeed-more"
import LiveMore from "../components/live-more"
import PlayMore from "../components/play-more"
import ExploreMore from "../components/explore-more"
import Campuses from "../components/campuses"
import MakeTheMost from "../components/make-the-most"
import MainLayout from "../layouts/main"
import Apply from "../components/apply"
import SeparatedAgenda from "../components/separated-agenda"

const NewAgendaPage: React.FC<PageProps> = () => (
  <MainLayout showNotifications={true}>
    <main id="main">
      <Welcome />
      <MakeTheMost />
      <SeparatedAgenda />
      <Apply />
      <ConnectMore />
      <DiscoverMore />
      <FlexMore />
      <CareerMatchBanner />
      <AffordMore />
      <ThriveMore />
      <SucceedMore />
      <LiveMore />
      <PlayMore />
      <ExploreMore />
      <Campuses />
    </main>
  </MainLayout>
)

export default NewAgendaPage

export const Head: HeadFC = () => (
  <title>Georgian College Virtual Open House</title>
)
