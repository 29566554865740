import * as React from "react"

const VohLockup = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 490.18 435.99"
    {...props}
    className={`fill-current ${props.className && props.className}`}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={239.53}
        y1={154.03}
        x2={239.53}
        y2={367.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <style>{`.cls-2{fill:#fff}`}</style>
    </defs>
    <title>{`01_Welcome_Lockup`}</title>
    <g
      style={{
        isolation: `isolate`,
      }}
    >
      <g id="Layer_1" data-name="Layer 1">
        <path
          d="M405.16 324h72.08v-20.8h-49.55v-20.8H472v-20.79h-44.31v-19.06h46.95v-20.81h-69.48Zm-13.44-94.19c-8.67-7.65-19.79-10.69-31.35-10.69-20.08 0-39 11.41-39 33.23 0 34.09 48 24 48 42.76 0 7.22-8.23 10.69-15.6 10.69a25.05 25.05 0 0 1-19.65-9.68l-16 17.63c9.68 9 21.24 12.85 34.39 12.85 21.09 0 39.44-10.83 39.44-33.66 0-35.82-47.1-23.83-47.1-42.47 0-7.66 8.81-10.55 15.17-10.55 5.49 0 12.71 2.17 16.32 6.79Zm-82.49-8.09H286.7v62c0 12.57-8.67 22.1-20.52 22.1s-20.66-9.53-20.66-22.1v-62H223v62.84c0 24.13 14.73 42 43.19 42s43.05-17.91 43.05-42ZM125 272.88c0-19.07 12.57-32.94 31.78-32.94s31.79 13.87 31.79 32.94-12.57 32.94-31.75 32.94S125 292 125 272.88m-23.4 0c0 32.8 23.26 53.74 55.18 53.74S212 305.68 212 272.88s-23.26-53.74-55.19-53.74-55.18 21-55.18 53.74M1.81 324h22.54v-44.2h43.77V324h22.54V221.74H68.12v38.14H24.35v-38.14H1.81Z"
          transform="translate(-.35 -.64)"
        />
        <path
          d="M405.16 434h69.48v-20.8h-46.95v-19.05H472v-20.8h-44.31v-20.81h49.55v-20.8h-72.08Zm-28.9-25c-3.61 4.62-10.83 6.79-16.32 6.79-6.36 0-15.17-2.89-15.17-10.55 0-18.63 47.1-6.64 47.1-42.47 0-22.82-18.35-33.66-39.44-33.66-13.15 0-24.71 3.9-34.39 12.86l16 17.62a25.08 25.08 0 0 1 19.65-9.68c7.37 0 15.6 3.47 15.6 10.69 0 18.79-48 8.67-48 42.77 0 21.81 18.92 33.22 39 33.22 11.56 0 22.68-3 31.35-10.69Zm-67-37.85c0-24.13-14.73-42-43.05-42S223 347.05 223 371.18V434h22.53v-62c0-12.57 8.67-22.11 20.66-22.11s20.52 9.54 20.52 22.11v62h22.53ZM125 382.88c0-19.07 12.57-32.94 31.78-32.94s31.79 13.87 31.79 32.94-12.57 32.94-31.75 32.94S125 402 125 382.88m-23.4 0c0 32.8 23.26 53.74 55.18 53.74S212 415.68 212 382.88s-23.26-53.74-55.19-53.74-55.18 20.95-55.18 53.74M1.81 434h22.54v-38.12h43.77V434h22.54V331.74H68.12V376H24.35v-44.26H1.81Z"
          transform="translate(-.35 -.64)"
          style={{
            opacity: 0.37,
            mixBlendMode: `screen`,
            fill: `url(#linear-gradient)`,
            isolation: `isolate`,
          }}
        />
        <path
          d="M296.88 206.92h22.54v-72.81h.29l44.64 72.81h29.47V104.64h-22.54v71.07H371l-43.48-71.07h-30.64Zm-85.81 0h72.09v-20.8h-49.55v-20.81H278v-20.8h-44.39v-19.07h46.95v-20.8h-69.49Zm-65-83.21h11.56c8.67 0 19.36 1.44 19.36 12C177 145.23 169 148 161.09 148h-15Zm-22.53 83.21h22.53v-39.87h16.47c21.53 0 37.85-7.37 37.85-31.35 0-24.27-17.62-31.06-38.86-31.06h-38Zm-97.96-51.14c0-19.07 12.57-32.94 31.78-32.94s31.78 13.87 31.78 32.94-12.56 32.94-31.78 32.94-31.78-13.87-31.78-32.94m-23.4 0c0 32.79 23.26 53.74 55.18 53.74s55.19-21 55.19-53.74S89.29 102 57.36 102 2.18 123 2.18 155.78M435.89 87.58h54.64V69.89H455V.64h-19.11ZM394.5 53h-21.24l10.56-27Zm-55.62 34.58H360l7.24-18.42H401l7.49 18.42h21.62L392.29.64h-15.84Zm3-86.94h-19.19v52.68c0 10.68-7.37 18.78-17.44 18.78s-17.56-8.1-17.56-18.78V.64h-19.15v53.41c0 20.51 12.52 35.74 36.71 35.74s36.59-15.23 36.59-35.74ZM218.56 87.58h19.15v-70h24.81V.64h-68.77v16.94h24.81Zm-75-70.73h12.53c7 0 14.85 1.35 14.85 10.07 0 9.45-9.08 9.7-16.08 9.7h-11.3Zm-19.19 70.73h19.16V52.83H153l18.17 34.75h23L172 50.86c12.3-2.21 18.81-11.66 18.81-23.86 0-20.26-15-26.4-32.79-26.4h-33.65Zm-32.9 0h19.15V.64H91.47Zm-56.37 0h15.35L86.31.64H65.8l-21.86 57h-.24L22.21.64H.35Z"
          transform="translate(-.35 -.64)"
        />
      </g>
    </g>
  </svg>
)

export default VohLockup
